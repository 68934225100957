import clsx from 'clsx';
import Button from '@/components/ui/buttons/Button.tsx';
import { Input } from '@/components/ui/shadcn/input';
import { toast } from '@/hooks/use-toast';
import { api } from '@/lib/api/client';

interface EmailSignupFormProps {
  className?: string;
  layoutStyle?: 'stacked' | 'horizontal';
  setCookieOnSubmit?: boolean;
  buttonText?: string;
}

async function onSubmit(e: React.FormEvent<HTMLFormElement>, setCookieOnSubmit?: boolean) {
  e.preventDefault();
  const formData = new FormData(e.target as HTMLFormElement);
  const email = formData.get('email') as string;
  const response = await api.auth.signupInitiate(email);

  if (response.data.success) {
    if (setCookieOnSubmit) {
      const expires = new Date();
      expires.setDate(expires.getDate() + 15);
      document.cookie = `popupClosed=true; expires=${expires.toUTCString()}; path=/`;
    }

    window.location.href = response.data.redirect;
  } else {
    toast({
      title: 'An error occurred. Please try again.',
      variant: 'destructive',
    });
  }
}

function EmailSignupForm({
  className,
  layoutStyle = 'horizontal',
  setCookieOnSubmit = false,
  buttonText = 'Subscribe',
}: EmailSignupFormProps) {
  return (
    <form
      className={clsx(
        'flex',
        layoutStyle === 'stacked' ? 'flex-col items-center' : 'items-center justify-center lg:justify-end',
        className,
      )}
      onSubmit={(e) => onSubmit(e, setCookieOnSubmit)}
    >
      <Input
        type='email'
        name='email'
        placeholder='YOUR EMAIL...'
        className={layoutStyle === 'stacked' ? 'mb-2' : ''}
      />
      <Button
        intent='primary'
        size='default'
        className={clsx('min-w-max', layoutStyle === 'stacked' ? 'w-full' : 'ml-2 !h-10')}
        type='submit'
      >
        {buttonText}
      </Button>
    </form>
  );
}

export default EmailSignupForm;
